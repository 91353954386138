#available{
  font-size: 4vmin;
  font-family: Courier;
}

.flags{
  margin: auto;
  text-align: center;
  width: 90vw;
}

.flag{
  width: 15vmin;
  height: 10vmin;
  margin-right: 1vmin;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

#title{
  display: inline;
  margin-top: 0;
  font-family: Courier;
  font-size: 18vmin;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#online{
  visibility: hidden;
  margin-bottom: 0;
  font-family: courier;
  font-size: 6vmin;
  text-align: center;
}

.center{
  clear: left;
  margin: auto;
  width: 80vw;
}

.center2{
  text-align: center;
  margin: auto;
  margin-top: 10vh;
}

#introText{
  font-size: 3.5vmin;
  font-family: Courier;
  text-align: center;
}

#instructText{
  font-size: 3.5vmin;
  font-family: Courier;
  text-align: center;
}

#ownBox{
  width: 30vw;
  height: 10vmin;
  font-size: 10vmin;
  border: 3px solid black;
  border-radius: 10px;
  font-family: Courier;
}

#learnBox{
  width: 30vw;
  height: 10vmin;
  font-size: 10vmin;
  border: 3px solid black;
  border-radius: 10px;
  font-family: Courier;
}

#go{
  visibility: hidden;
  width: 22vmin;
  height: 14vmin;
  font-size: 12vmin;
  font-family: Courier;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: black;
  background-color: #4CAF50;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
}

#go:hover{
  background-color: #3e8e41
}

#go:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

input:focus, textarea:focus, select:focus {
outline-offset: 0px !important;
outline: none !important;
}

.background{
  position: fixed;
  top: 0;
  z-index: -1;
}

button:focus, text:focus, select:focus {
outline-offset: 0px !important;
outline: none !important;
}

@media (max-width: 1080px){
  #ownBox{
    width: 34vw;
  }
  #learnBox{
    width: 34vw;
  }
  .center{
    width: 90vw;
  }
}
