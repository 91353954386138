.home{
  position: fixed;
  right: 2vw;
  text-align: center;
}

#homeButton{
  height: 10vmin;
  width: 10vmin;
}

#homeButton:hover{
  background-color: #D3D3D3;
}

#dialogBox{
  font-family: Courier;
  width: 98vw;
  height: 84vh;
  background-color: white;
  font-size: 10vmin;
  border-radius: 10px;
  border: 3px solid black;
}

#inputBox{
  font-family: Courier;
  width: 77vw;
  height: 10vmin;
  font-size: 10vmin;
  float: left;
  border-radius: 10px;
  border: 3px solid black;
}

#send{
  width: 10vw;
  height: 11vmin;
  font-size: 6vmin;
  font-weight: bold;
  color: white;
  background-color: #2ECC40;
  border-radius: 10px;
  border: 0px;
}

#send:hover{
  background-color: #3e8e41;
}

#send:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

#leave{
  width: 10vw;
  height: 11vmin;
  font-size: 6vmin;
  font-weight: bold;
  color: white;
  background-color: #FF4136;
  border-radius: 10px;
  border: 0px;
}

#leave:hover{
  background-color: #C42D2D;
}

#leave:active {
  background-color: #C42D2D;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

#search{
  width: 18vw;
  height: 11vmin;
  font-size: 6vmin;
  font-weight: bold;
  color: white;
  background-color: #2ECC40;
  border-radius: 10px;
  border: 0px;
}

#search:hover{
  background-color: #3e8e41;
  color: white;
}

#search:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

button:focus, text:focus, select:focus {
outline-offset: 0px !important;
outline: none !important;
}

@media (max-width: 1400px){
  #inputBox{
    width: 50vw;
  }
  #send{
    width: 20vw;
  }
  #leave{
    width: 20vw;
  }
  #search{
    width: 25vw;
  }
}
